import { useRouter } from 'next/router'
import { Controller, useForm } from 'react-hook-form'
import {
  Alert,
  FormControl,
  styled,
  TextField,
  useTheme,
} from '@mui/material'

import Button from 'components/commons/Button'
import LoadingButton from 'components/commons/LoadingButton'
import Stack from 'components/commons/Stack'
import Typography from 'components/commons/CustomTypography'
import { ChatPeer, ErrorVariant } from 'stores/domains/User/Message'
import trackEvent from 'src/trackers'
import { getDateTimeUTC } from 'helpers/date-time'
import {
  incrementPeopleProperties,
  setOncePeopleProperties,
  setPeopleProperties,
} from 'helpers/analytics'

import BlockRoundedIcon from '@mui/icons-material/BlockRounded'
import SendRoundedIcon from '@mui/icons-material/SendRounded'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface FormMessageField {
  message: string
}

interface MessageFieldProps {
  isMobile?: boolean
  isBlocked?: boolean
  peerName?: string
  userProfile?: any
  currentPeer?: ChatPeer
  /* eslint-disable-next-line no-unused-vars */
  onClickSend?: (message: string) => void
  onClickUnblock?: Function
  /* eslint-disable-next-line no-unused-vars */
  onChangeText?: (message: string) => void
  defaultValue?: string
  errorMessage?: ErrorVariant
  onCloseAlert?: Function
}

const [MIN_LENGTH, MAX_LENGTH, DISABLE_FROM_LENGTH] = [3, 500, 480]

export default function MessageField({
  isMobile = false,
  isBlocked = false,
  defaultValue = '',
  userProfile = {},
  currentPeer,
  peerName,
  onClickSend,
  onClickUnblock,
  onChangeText,
  errorMessage,
  onCloseAlert,
}: MessageFieldProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { pathname, query } = useRouter()
  const trackerData = { pathname, query }
  const {
    control,
    handleSubmit,
    formState: { isValid },
    resetField,
    setValue,
  } = useForm<FormMessageField>({
    mode: 'onChange',
    defaultValues: { message: '' },
    reValidateMode: 'onChange',
  })

  const txtMessage = useRef<HTMLTextAreaElement>(null)
  const [isRequesting, setIsRequesting] = useState(false)

  const isSenderCreator = userProfile?.creator?.status === 'verified'
  const fromUserData = {
    from_user_id: userProfile?.user_id,
    from_username: userProfile?.username,
    from_user_type: isSenderCreator ? 'creator' : 'supporter',
    from_creator_id: isSenderCreator
      ? userProfile?.creator?.creator_id
      : 'none',
    from_creator_name: isSenderCreator
      ? userProfile?.creator?.name
      : 'none',
  }
  const targetUserData = {
    to_user_id: currentPeer.id || 'none',
    to_username: currentPeer.username || 'none',
    to_user_type: currentPeer.isCreator ? 'creator' : 'supporter',
    to_creator_id: currentPeer?.creator?.id || 'none',
    to_creator_name: currentPeer?.creator?.name || 'none',
  }
  const userData = {
    ...fromUserData,
    ...targetUserData,
  }

  const onSubmit = async (data: FormMessageField) => {
    trackEvent.user('send_message', trackerData, {
      ...userData,
      message: data.message,
      is_message_read: false,
    })

    const dataTime = getDateTimeUTC(new Date().toISOString())
    setOncePeopleProperties({
      lifetime_first_send_message_datetime: dataTime,
    })
    setPeopleProperties({
      lifetime_last_send_message_datetime: dataTime,
    })
    incrementPeopleProperties({
      lifetime_num_user_message: 1,
    })

    setIsRequesting(true)
    if (onClickSend) onClickSend(data.message)
    setTimeout(() => {
      if (errorMessage) {
        setValue('message', data.message)
      } else {
        resetField('message')
      }
      setIsRequesting(false)
    }, 500)
  }

  const handleKeyPressMessage = (event) => {
    if (event.target.value.length >= MAX_LENGTH) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  const handleChangeMessage = (event) => {
    if (onChangeText) onChangeText(event.target.value)
  }

  const handleUnblock = () => {
    if (onClickUnblock) onClickUnblock()
  }

  const handleCloseAlert = () => {
    if (onCloseAlert) onCloseAlert()
  }

  useEffect(() => {
    setValue('message', defaultValue)
  }, [defaultValue])

  return (
    <Stack direction="column" width="100%">
      {errorMessage && (
        <Stack
          paddingX={2}
          paddingY={1}
          bgcolor={theme.palette.background.primary}
          color={theme.palette.text.primary}
          position={isMobile ? 'fixed' : 'inherit'}
          bottom={isMobile && '78px'}
          left={isMobile && 0}
        >
          <Alert
            severity={errorMessage.type}
            variant="outlined"
            onClose={handleCloseAlert}
            sx={{
              paddingY: 0,
              color: theme.palette.text.primary,
              fontSize: '12px',
              lineHeight: '20px',
              borderRadius: '6px',
            }}
          >
            {errorMessage.message}
          </Alert>
        </Stack>
      )}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={isBlocked ? 'start' : 'center'}
        spacing={2}
        padding={2}
        paddingRight={0}
        sx={
          !isMobile && {
            borderBottomRightRadius: '12px',
            height: '100%',
          }
        }
        position={isMobile ? 'fixed' : 'inherit'}
        bottom={isMobile && 0}
        left={isMobile && 0}
        width="100%"
        id="c-users-chat-field"
        borderTop={
          isBlocked
            ? `1px solid ${theme.palette.background.tertiery}`
            : `1px solid ${theme.palette.border.bold}`
        }
      >
        {isBlocked && (
          <Typography as="span" size="sm" color="primary">
            <BlockRoundedIcon fontSize="small" /> Kamu memblokir{' '}
            {peerName}.
            <Button
              buttonVariant="nude"
              onClick={handleUnblock}
              sx={{ padding: '0px !important' }}
            >
              Unblock
            </Button>
          </Typography>
        )}
        {!isBlocked && (
          <StyledForm
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormControl variant="standard" fullWidth>
              <Controller
                name="message"
                control={control}
                rules={{
                  required: true,
                  minLength: MIN_LENGTH,
                  maxLength: MAX_LENGTH,
                }}
                render={({ field }) => (
                  <Stack spacing={1}>
                    <TextField
                      ref={txtMessage}
                      name="message"
                      id="i-users-message-input"
                      placeholder={t('writeMessageHere')}
                      multiline
                      maxRows={3}
                      disabled={isRequesting}
                      onKeyPress={handleKeyPressMessage}
                      onKeyUp={handleChangeMessage}
                      InputProps={{
                        sx: {
                          margin: 0,
                          padding: '12px',
                          borderRadius: '8px',
                          backgroundColor:
                            theme.palette.background.secondary,
                          border: `1px solid ${theme.palette.border.bold}`,
                          '.Mui-disabled': {
                            WebkitTextFillColor:
                              theme.palette.text.tertiery,
                            color: theme.palette.text.secondary,
                          },
                        },
                      }}
                      {...field}
                    />
                    <Stack
                      width="100%"
                      alignItems="flex-end"
                      sx={{
                        display:
                          field.value.length >= DISABLE_FROM_LENGTH
                            ? 'flex'
                            : 'none',
                      }}
                    >
                      <Typography
                        as="span"
                        size="xs"
                        color="tertiery"
                      >
                        {field.value.length}/{MAX_LENGTH}
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              />
            </FormControl>
            <LoadingButton
              type="submit"
              buttonVariant="nude"
              size="small"
              loading={isRequesting}
              disabled={!isValid}
            >
              <SendRoundedIcon />
            </LoadingButton>
          </StyledForm>
        )}
      </Stack>
    </Stack>
  )
}

const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  padding: 0,
  margin: 0,
  height: '100%',
})
